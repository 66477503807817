import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 500.000000 500.000000">
    <title>Logo</title>
    
    <g transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
        <path
          d="M1533 4187 l-963 -560 0 -1127 0 -1127 966 -561 965 -562 962 560
          962 559 0 1131 0 1131 -955 556 c-525 305 -959 557 -965 558 -5 2 -443 -249
          -972 -558z m1487 -1209 l-1 -183 -275 -375 -274 -375 275 -3 275 -2 0 -180 0
          -180 -525 0 -525 0 0 180 0 179 280 383 281 383 -281 3 -280 2 0 175 0 175
          525 0 525 0 0 -182z"
          fill="currentColor"
        />
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
     
    </g>
  </svg>
);

export default IconLogo;
